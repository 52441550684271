.page{
    display: flex;
    height: 100%;
    position: relative;
}

main{
    /* padding: 10rem 5rem 10rem 10rem; */
    padding-left: 6.7rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media all and (max-width: 768px) {
    main{
        padding: 0 0 5rem 6.5rem;
        flex-direction: column;
    }
}

@media all and (max-width: 468px) {
    main{
        padding: 0 0 5rem 0rem;
    }
}