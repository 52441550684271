.item{
    width: 100%;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid #b4b4b4;
}

.title {
    font-size: 1.6rem;
    color: #141414;
}

.item:hover .title{
    color: #0000ff;
}

.image {
    margin: 1.5rem auto;
    width: 10rem;
}
.img {
    width: 100%;
}