.loginForm {
    width: 43%;
    align-self: flex-end;
    margin: 0 4rem 3rem 0;
    text-align: center;
}

.loginInputs{
    position: relative;
}

.button {
    background: white;
    border: 1px solid white;
    padding: 1.5rem 4rem;
    border-radius: 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button:hover {
    background-color: #0000ff;
    font-size: 1.6rem;
    color: white;
}

.errMsg {
    position: absolute;
    right: 0;
    top: 100%;
    color: #f59f4e;
    font-size: 1.4rem;
}

.forgotPass {
    color: #fff;
    font-size: 1.6rem;
    margin-top: 2rem;
    display: block;
}

@media all and (max-width: 550px) {
    .loginForm {
        width: 60%;
        margin: 0;
    }
}
