.sidebar {
    color: white;
    background-color: #14141b;
    width: 6.7rem;
    padding: 4rem 0;
    height: calc(100vh - 6rem);
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar:hover {
    width: 28rem;
}

.sidebarLink,
.logOut {
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    padding: 1rem 2.5rem;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.sidebarLink:hover {
    background-color: #40435a;
}

.linkIcon,
.logOutIcon {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullTitle,
.logOutTitle {
    transition: all 0.4s ease-in-out;
    /* display: none; */
    display: block;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    left: 55px;
}

.sidebar:hover .fullTitle,
.sidebar:hover .logOutTitle {
    opacity: 1;
    visibility: visible;
    /* display: block; */
}

@media all and (max-width: 468px) {
    .sidebar {
        width: 100%;
        padding: 3rem 0;
        display: none;
    }

    .sidebarShowMob {
        display: block;
    }

    .sidebar:hover {
        width: 100%;
    }

    .sidebarLink {
        font-size: 1.8rem;
        padding: 1rem 2.5rem;
        flex-wrap: wrap;
    }

    .fullTitle,
    .logOutTitle{
        flex: 1;
        opacity: 1;
        visibility: visible;
        position: relative;
    }
}
