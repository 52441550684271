.fullscreen-editor{
  position: absolute;
  top: 0;
  right: 0;
}

.Modal {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 101;
    width: 100%;
    height: 100vh;
    padding: 0 2.5rem;
  }

  .Modal .ck.ck-editor__editable_inline{
    padding: 1rem 2rem;
    height: 87vh;
    overflow-y: auto;
}

  .Modal:focus{
    outline: none;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.596);
    z-index: 100;
  }

  .Modal .editor{
    height: 100%;
  }

  .Modal .editorRoot{
    height: 100%;
  }
  .Modal .richEditor{
    height: 90%;
  }

  .open-modal {
    background:transparent;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    margin: 0 1.5rem;
  }

  .open-modal:focus{
    outline: none;
  }

  .close-wrapper{
    display: flex;
    justify-content: flex-end;
  }

  .close-modal {
    background:transparent;
    border: none;
    font-size: 2.5rem;
    cursor: pointer;
  }

  .close-modal:focus{
    outline: none;
  }
