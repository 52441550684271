.languageBar{
    position: fixed;
    right: 0;
    top: 6rem;
    z-index: 5;
    display: flex;
    margin-right: 5rem;
}

@media all and (max-width: 767px) {
    .languageBar{
        top: 36rem;
    }
}