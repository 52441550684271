.formWrapper {
    width: 80%;
    overflow-y: auto;
    height:  calc(100vh - 11rem);
    padding: 5rem 1.5rem;
    position: relative;
 }

 @media all and (max-width: 768px) {
     .formWrapper {
        width: 100%;
        height: 60vh;
     }
 }

 @media all and (max-width: 468px) {
     .formWrapper {
        padding: 2rem 1.5rem;
     }
 }