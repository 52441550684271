.fileInputContainer{
    margin: 2rem 0;

}

.fileInputWrapper{
    margin-bottom: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.label {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1.5rem;
}

.fileInput {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 3.5rem;
   text-align:center;
}

.fileInput input {
    width: 15rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
}

.fileCustom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 1rem 2.5rem;
    text-align: center;
    border-radius: 5px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size: 1.6rem;
    color: white;
    background-color: #0000ff;
    transition: all .2s ease-in-out;
}

.fileCustom:hover {
    background-color: #14141b;

}

.filePreviewWrapper {
    border-top: 1px solid #b4b4b4;
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.documentsPreviewWrapper {
    flex-direction: column;
}

.filePreview {
    width: 20rem;
    margin: 2rem 1.5rem;
    position: relative;
}

.documentsPreview{
    width: auto;
    padding-bottom: 1rem;
    border-bottom: 1px solid #b4b4b4;
}

.document {
    font-size: 1.6rem;
    color: #0000ff;
    font-weight: 500;
}

.document:hover {
    color: #14141b;
}

.document i {
    font-size: 2rem;
    margin-right: 8px;
}

.filePreview img{
    width: 100%;
}

.filePreviewRemove {
    position: absolute;
    bottom: 100%;
    left: 100%;
    font-size: 16px;
    color: #0000ff;
    padding: 3px;
    cursor: pointer;
}

