@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.LogIn_login__2GwHk {
    width: 100%;
    height: 100vh;
    background: linear-gradient(-45deg, #0000FF 0%, #0000FF 50%, transparent 50%);
    position: relative;
}

.LogIn_loginBox___pgbF {
    width: 70%;
    height: 60rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(-45deg, #0000FF 0%, #0000FF 50%, transparent 50%);
    box-shadow: 0px 0px 8px 0.5px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-between;
}

.LogIn_titleContainer__21bJ0 {
    align-self: center;
    width: 43%;
    text-align: center;
}

.LogIn_logo__24Z-b{
    width: 12rem;
    margin: 0 auto;
}

.LogIn_logo__24Z-b img{
    width: 100%;
}

.LogIn_mainTitle__3y7fN h2{
    font-size: 3rem;
    color: #171718;
}

.LogIn_logInTitle__3Cf5S {
    font-size: 2.2rem;
    letter-spacing: 2px;
}


@media all and (max-width: 1040px) {
    .LogIn_loginBox___pgbF {
        width: 90%;
    }
}

@media all and (max-width: 550px) {

    .LogIn_loginBox___pgbF{
        width: 95%;
        height: 60rem;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 1.5rem;
    }

    .LogIn_titleContainer__21bJ0 {
        align-self: flex-start;
        width: 75%;
    }

    .LogIn_mainTitle__3y7fN h2{
        margin-bottom: 2rem;
    }
    
}
.LoginInput_loginInput__3yXdo {
    position: relative;
    width: 100%;
    display: flex;
    margin: 3.5rem 0 ;
}

.LoginInput_label__3MlsL {
    position: absolute;
    margin: 1rem;
    top: 0;
    left: .5rem;
    font-size: 2rem;
    padding: 0 .5rem;
    background:#0000FF;
    transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
    color: white;
}

.LoginInput_input__eBmAz {
    width: 100%;
    padding:1rem 2rem;
    border: 1px solid white;
    font-size: 2rem;
    background:transparent;
    border-radius: 3rem;
    color: white;
}

.LoginInput_input__eBmAz:focus {
    outline: none;
}

.LoginInput_input__eBmAz:focus + label,
.LoginInput_active__2Hu_L + label {
    top: -2.5rem;
    font-size: 1.8rem;

}

.LoginInput_input__eBmAz.LoginInput_invalid__2tFRb {
    border: 1px solid #f59f4e;
}

.LoginInput_input__eBmAz.LoginInput_invalid__2tFRb + label {
    color: #f59f4e;
}

.LoginInput_errorMsg__b-Tdu{
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 3px;
    font-size: 1.2rem;
    color:#f59f4e;
}
.LogInForm_loginForm__1B4Fq {
    width: 43%;
    align-self: flex-end;
    margin: 0 4rem 3rem 0;
    text-align: center;
}

.LogInForm_loginInputs__1iqRC{
    position: relative;
}

.LogInForm_button__2KtN7 {
    background: white;
    border: 1px solid white;
    padding: 1.5rem 4rem;
    border-radius: 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.LogInForm_button__2KtN7:hover {
    background-color: #0000ff;
    font-size: 1.6rem;
    color: white;
}

.LogInForm_errMsg__26qXZ {
    position: absolute;
    right: 0;
    top: 100%;
    color: #f59f4e;
    font-size: 1.4rem;
}

.LogInForm_forgotPass__Kmvp7 {
    color: #fff;
    font-size: 1.6rem;
    margin-top: 2rem;
    display: block;
}

@media all and (max-width: 550px) {
    .LogInForm_loginForm__1B4Fq {
        width: 60%;
        margin: 0;
    }
}

.Header_header__tw3qr{
    background-color:#0000FF;
    padding: 1rem 0;
    height: 6rem;
}

.Header_headerContainer__1KQE1 {
    max-width: 1200px;
    width: 97%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.Header_logo__2WZZJ {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.Header_mobMenuIcon__1j_Ii {
    display: none;
}

.Header_logo__2WZZJ img {
    width: 2.5rem;
}

.Header_title__2iTLH {
    color: white;
    align-self: center;
    font-size: 2.6rem;
    letter-spacing: 1px;
}

.Header_userInfo__20lNb {
    align-self: flex-end;
    font-size: 1.6rem;
    color: white;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.Header_userInfo__20lNb:hover {
    color: #7EC8E3;
}

.Header_icon__3oikx{
    margin-right: 1.5rem;
    font-size: 2.2rem;
}

@media all and (max-width: 768px) {
    .Header_userName__2r0vI{
        display: none;
    }

    .Header_logoMenuContainer__3Vp5B {
        display: flex;
        align-items: center;
    }

    .Header_mobMenuIcon__1j_Ii {
        display: block;
        color: white;
        font-size: 20px;
        margin-left: 8px;
        cursor: pointer;
    }
}
.SubItemListItem_subItem__s92DM{
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    padding: 1rem 2.5rem;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    display: none;
}

.SubItemListItem_subItem__s92DM.SubItemListItem_active__1ov_i {
    display: block;
}

.SubItemListItem_subItem__s92DM:hover{
    background-color: #40435a;
}

.SubItemListItem_linkIcon__3OhMG{
    margin-right: 5px;
}

@media all and (max-width: 468px) {
    .SubItemListItem_subItem__s92DM{
        padding: 2rem 3.5rem;
        
    }
}
.SubItemList_sidebarList__2oEHs {
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.SubItemList_sidebarLink__2jV7Y{
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    padding: 1rem 2.5rem;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.SubItemList_sidebarList__2oEHs:hover {
    background-color: #40435a;
}

.SubItemList_linkIcon__25VEv {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SubItemList_fullTitle__2WWbF {
    display: inline-flex;
    justify-content: space-between;
    flex: 1 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
    position: absolute;
    left: 55px;
}

.SubItemList_fullTitle__2WWbF.SubItemList_active__1Nnw1 {
    width: 20rem;
   opacity: 1;
   visibility: visible;
}

.SubItemList_subList__2PlKL {
    position: absolute;
    left: 100%;
    top: 0;
    width: 28rem;
    list-style-type: none;
    background-color: #14141b;
    display: none;
}

.SubItemList_subList__2PlKL.SubItemList_active__1Nnw1 {
    display: block;
    
}

@media all and (max-width: 468px) {

    .SubItemList_sidebarList__2oEHs {
        font-size: 1.8rem;
        flex-wrap: wrap;
        width: 100%;
    }

    .SubItemList_sidebarList__2oEHs.SubItemList_active__1Nnw1{
        background-color: #40435a;
    }

    .SubItemList_fullTitle__2WWbF {
        flex: 1 1;
        opacity: 1;
        visibility: visible;
        position: relative;
    }

    .SubItemList_subList__2PlKL {
        position: relative;
        left:0;
        top: 0;
        width: 100%;
        display: none;
        transition: all 0.2 ease-in-out;
        border-top: 1px solid #14141b;
        border-bottom: 1px solid #14141b;
        margin: 2rem 0;
        background-color: #40435a;
    }

    .SubItemList_subList__2PlKL.SubItemList_active__1Nnw1 {
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        display: block;
    }
    
}

.Sidebar_sidebar__1vai4 {
    color: white;
    background-color: #14141b;
    width: 6.7rem;
    padding: 4rem 0;
    height: calc(100vh - 6rem);
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Sidebar_sidebar__1vai4:hover {
    width: 28rem;
}

.Sidebar_sidebarLink__1l-gf,
.Sidebar_logOut__19V63 {
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    padding: 1rem 2.5rem;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.Sidebar_sidebarLink__1l-gf:hover {
    background-color: #40435a;
}

.Sidebar_linkIcon__2KCs8,
.Sidebar_logOutIcon__3ZB84 {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Sidebar_fullTitle__2xhxV,
.Sidebar_logOutTitle__2-bQ0 {
    transition: all 0.4s ease-in-out;
    /* display: none; */
    display: block;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    left: 55px;
}

.Sidebar_sidebar__1vai4:hover .Sidebar_fullTitle__2xhxV,
.Sidebar_sidebar__1vai4:hover .Sidebar_logOutTitle__2-bQ0 {
    opacity: 1;
    visibility: visible;
    /* display: block; */
}

@media all and (max-width: 468px) {
    .Sidebar_sidebar__1vai4 {
        width: 100%;
        padding: 3rem 0;
        display: none;
    }

    .Sidebar_sidebarShowMob__3AJer {
        display: block;
    }

    .Sidebar_sidebar__1vai4:hover {
        width: 100%;
    }

    .Sidebar_sidebarLink__1l-gf {
        font-size: 1.8rem;
        padding: 1rem 2.5rem;
        flex-wrap: wrap;
    }

    .Sidebar_fullTitle__2xhxV,
    .Sidebar_logOutTitle__2-bQ0{
        flex: 1 1;
        opacity: 1;
        visibility: visible;
        position: relative;
    }
}

.Page_page__3VAVI{
    display: flex;
    height: 100%;
    position: relative;
}

main{
    /* padding: 10rem 5rem 10rem 10rem; */
    padding-left: 6.7rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media all and (max-width: 768px) {
    main{
        padding: 0 0 5rem 6.5rem;
        flex-direction: column;
    }
}

@media all and (max-width: 468px) {
    main{
        padding: 0 0 5rem 0rem;
    }
}

.LoadingSpinner_loaderContainer__1Dz2L {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.SideListItem_item__esZeu{
    width: 100%;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid #b4b4b4;
}

.SideListItem_title__R6gsR {
    font-size: 1.6rem;
    color: #141414;
}

.SideListItem_item__esZeu:hover .SideListItem_title__R6gsR{
    color: #0000ff;
}

.SideListItem_image__2yyqx {
    margin: 1.5rem auto;
    width: 10rem;
}
.SideListItem_img__2pYew {
    width: 100%;
}
.SearchInput_searchInput__2oJSl{
    display: flex;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    align-items: center;
    padding: 1rem .5rem;
    margin-bottom: 2rem;
}

.SearchInput_searchInput__2oJSl input{
    width:100%;
    border: none;
    font-size: 1.6rem;
    background: transparent;
    
}

.SearchInput_searchInput__2oJSl input:focus{
    outline: none;
}

.SearchInput_searchInput__2oJSl input::-webkit-input-placeholder{
    color: #b4b4b4;
}

.SearchInput_searchInput__2oJSl input:-ms-input-placeholder{
    color: #b4b4b4;
}

.SearchInput_searchInput__2oJSl input::-ms-input-placeholder{
    color: #b4b4b4;
}

.SearchInput_searchInput__2oJSl input::placeholder{
    color: #b4b4b4;
}

.SearchInput_icon__1G8Nl{
    color: #b4b4b4;
    font-size: 1.6rem;
    margin-right: 5px;
}
.SideList_sideList__3HJ8n {
    border-right: 1px solid #b4b4b4;
    margin-right: 1.5rem;
    overflow-y: auto;
    height:  calc(100vh - 6rem);
    padding: 1.5rem 1rem;
    width: 20%;
    display: flex;
    flex-direction: column;
}

.SideList_noResults__NHmqX{
    font-size: 1.6rem;
}

.SideList_moreBtn__2Ih8g {
    border: 1px solid #0000ff;
    padding: 5px 8px;
    border-radius: 30px;
    background:transparent;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #0000ff;
    cursor: pointer;
    margin-top: 2rem;
    align-self: center;
}

.SideList_moreBtn__2Ih8g:hover {
    border: 1px solid #0000ff;
    background-color: #0000ff;
    color: white;
}

.SideList_moreBtn__2Ih8g:focus{
    outline: none;
}

@media all and (max-width: 768px) {
    .SideList_sideList__3HJ8n{
        width: 100%;
        border-right: none;
        height: 30rem;
        border-bottom: 2px solid #b4b4b4;
        background-color: #f7f7f7;
    }
}
.FormButton_buttonContainer__3e96H {
    margin:1rem;
    display: flex;
    justify-content: flex-end;
}

.FormButton_button__X_-aD {
    background: #0000ff;
    border: 1px solid #0000ff;
    color: white;
    padding: 1.5rem 4rem;
    border-radius: 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.FormButton_redButton__12tgO {
    background: #d40404;
    border: 1px solid #d40404;
}

.FormButton_greenButton__3Kx9T {
    background: #116530;
    border: 1px solid #116530;
}

.FormButton_button__X_-aD:hover {
    background-color: #14141b;
    border: 1px solid #14141b;
    font-size: 1.6rem;
}

.FormButton_icon__3datd {
    font-size: 2.4rem;
}
.DeleteButton_buttonsContainer__3UlK8 {
    display: flex;
    justify-content: flex-end;
}

.DeleteButton_confirmationOverlay__3bsIo{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 5;
    top: 0;
    left: 0;
}

.DeleteButton_confirmation__tLvQD {
    position: absolute;
    top: 26%;
    left: 25%;
    background-color: white;
    border: 1px solid #b4b4b4;
    width: 60%;
    padding: 5rem 3rem;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}



.DeleteButton_confirmButtons__3xDxm {
    display: flex;
    justify-content: flex-end;
}

.DeleteButton_text__2GAsv {
    font-size: 1.8rem;
    margin-bottom: 5rem;
    margin-top: 1rem;
}

.DeleteButton_lockScroll__2gnao {
    overflow-y: hidden;
}
.FormSection_buttonsContainer__2WqU8 {
    display: flex;
    justify-content: flex-end;
}

.FormSection_prevBtn__Nhr-4 {
    margin-right: auto;
}

.FormSection_formError__2xK0m {
    color: #d40404;
    font-size: 1.6rem;
    text-align: right;
    margin-top: 5px;
    font-weight: 500;
}

.LanguageTab_languageTab__3QMOs {
    background-color: #141414;
    color: white;
    border-radius: 0 0 5px 5px;
    padding: .5rem 2rem;
    font-size: 1.6rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.LanguageTab_languageTab__3QMOs:hover{
    background-color: #707070;
}

.LanguageTab_languageTab__3QMOs.LanguageTab_active__2LMMg{
    background-color: #707070;
}
.LanguageBar_languageBar__2H2Wp{
    position: fixed;
    right: 0;
    top: 6rem;
    z-index: 5;
    display: flex;
    margin-right: 5rem;
}

@media all and (max-width: 767px) {
    .LanguageBar_languageBar__2H2Wp{
        top: 36rem;
    }
}
.text-editor{
    position: relative;
    margin-bottom: 3rem;
}

.editor-label {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1rem;
}

.ck.ck-editor__editable_inline{
    padding: 1rem 2rem;
    height: 30rem;
    overflow-y: auto;
}

.ck-editor p{
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
}

.ck-editor ul li,
.ck-editor ol li{
    font-size: 1.6rem;
}

.ck-editor figure {
    width: 80%;
}

.ck.ck-editor h2{
    font-size: 2.2rem;
    text-align: center;
}

.ck.ck-editor h3{
    font-size: 2rem;
    text-align: center;
}

.ck.ck-editor h4{
    font-size: 1.8rem;
    text-align: center;
}

.ck-editor table td{
    font-size: 12px;
}


.fullscreen-editor{
  position: absolute;
  top: 0;
  right: 0;
}

.Modal {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 101;
    width: 100%;
    height: 100vh;
    padding: 0 2.5rem;
  }

  .Modal .ck.ck-editor__editable_inline{
    padding: 1rem 2rem;
    height: 87vh;
    overflow-y: auto;
}

  .Modal:focus{
    outline: none;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.596);
    z-index: 100;
  }

  .Modal .editor{
    height: 100%;
  }

  .Modal .editorRoot{
    height: 100%;
  }
  .Modal .richEditor{
    height: 90%;
  }

  .open-modal {
    background:transparent;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    margin: 0 1.5rem;
  }

  .open-modal:focus{
    outline: none;
  }

  .close-wrapper{
    display: flex;
    justify-content: flex-end;
  }

  .close-modal {
    background:transparent;
    border: none;
    font-size: 2.5rem;
    cursor: pointer;
  }

  .close-modal:focus{
    outline: none;
  }

.FormWrapper_formWrapper__kknWq {
    width: 80%;
    overflow-y: auto;
    height:  calc(100vh - 11rem);
    padding: 5rem 1.5rem;
    position: relative;
 }

 @media all and (max-width: 768px) {
     .FormWrapper_formWrapper__kknWq {
        width: 100%;
        height: 60vh;
     }
 }

 @media all and (max-width: 468px) {
     .FormWrapper_formWrapper__kknWq {
        padding: 2rem 1.5rem;
     }
 }
.Input_inputWrapper__2525W {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
}

.Input_label__6TCqH {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1rem;
}

.Input_input__DVE_H {
    width: 100%;
    border: 1px solid #b4b4b4;
    padding: 1rem .5rem;
    border-radius: 5px;
    font-size: 1.6rem;
}

.Input_input__DVE_H:focus{
    outline: none;
}

.Input_errorMsg__4jqhC {
    color: #d40404;
    font-size: 12px;
    margin-top: 5px;
}

.Input_invalid__1fNLG {
    border: 1px solid #d40404;
}
.FileInput_fileInputContainer__2uUYP{
    margin: 2rem 0;

}

.FileInput_fileInputWrapper__1d6Et{
    margin-bottom: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.FileInput_label__2NdAe {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1.5rem;
}

.FileInput_fileInput__28isS {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 3.5rem;
   text-align:center;
}

.FileInput_fileInput__28isS input {
    width: 15rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
}

.FileInput_fileCustom__3Kj4W {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 1rem 2.5rem;
    text-align: center;
    border-radius: 5px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size: 1.6rem;
    color: white;
    background-color: #0000ff;
    transition: all .2s ease-in-out;
}

.FileInput_fileCustom__3Kj4W:hover {
    background-color: #14141b;

}

.FileInput_filePreviewWrapper__1Rrpc {
    border-top: 1px solid #b4b4b4;
    padding-top: 2rem;
    display: flex;
}

.FileInput_filePreview__8nop- {
    width: 20rem;
    margin: 1rem;
    position: relative;.FileInput_fileInputContainer__2uUYP{
        margin: 2rem 0;
    
    }
    
    .FileInput_fileInputWrapper__1d6Et{
        margin-bottom: 3rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .FileInput_label__2NdAe {
        font-size: 1.6rem;
        font-weight: 500;
        color: #171718;
        margin-bottom: 1.5rem;
    }
    
    .FileInput_fileInput__28isS {
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 3.5rem;
       text-align:center;
    }
    
    .FileInput_fileInput__28isS input {
        width: 15rem;
        margin: 0;
        filter: alpha(opacity=0);
        opacity: 0;
    }
    
    .FileInput_fileCustom__3Kj4W {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        padding: 1rem 2.5rem;
        text-align: center;
        border-radius: 5px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size: 1.6rem;
        color: white;
        background-color: #0000ff;
        transition: all .2s ease-in-out;
    }
    
    .FileInput_fileCustom__3Kj4W:hover {
        background-color: #14141b;
    
    }
    
    .FileInput_filePreviewWrapper__1Rrpc {
        border-top: 1px solid #b4b4b4;
        padding-top: 2rem;
        display: flex;
        flex-wrap: wrap;
    }
    
    .FileInput_documentPreviewWrapper__3OTGe {
        flex-direction: column;
    }
    
    .FileInput_filePreview__8nop- {
        width: 20rem;
        margin: 2rem 1.5rem;
        position: relative;
    }
    
    .FileInput_documentPreview__2XoVW{
        width: auto;
        padding-bottom: 1rem;
        border-bottom: 1px solid #b4b4b4;
    }
    
    .FileInput_document__zESw4 {
        font-size: 1.6rem;
        color: #0000ff;
        font-weight: 500;
    }
    
    .FileInput_document__zESw4:hover {
        color: #14141b;
    }
    
    .FileInput_document__zESw4 i {
        font-size: 2rem;
        margin-right: 8px;
    }
    
    .FileInput_filePreview__8nop- img{
        width: 100%;
    }
    
    .FileInput_filePreviewRemove__k2iB1 {
        position: absolute;
        bottom: 100%;
        left: 100%;
        font-size: 16px;
        color: #0000ff;
        padding: 3px;
        cursor: pointer;
    }
    
    
}

.FileInput_filePreview__8nop- img{
    width: 100%;
}

.FileInput_filePreviewRemove__k2iB1 {
    position: absolute;
    bottom: 100%;
    left: 100%;
    font-size: 16px;
    color: #0000ff;
    padding: 3px;
    cursor: pointer;
}



.UserProfile_userProfile__1NAEb {
    width: 70%;
    margin: 0 auto;
}

.UserProfile_button__30VhI {
    background: #0000ff;
    border: 1px solid #0000ff;
    color: white;
    padding: 1.5rem 4rem;
    border-radius: 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.UserProfile_button__30VhI:hover {
    background-color: #14141b;
    border: 1px solid #14141b;
    font-size: 1.6rem;
}
.MultipleFilesInput_fileInputContainer__11rPP{
    margin: 2rem 0;

}

.MultipleFilesInput_fileInputWrapper__3uUrr{
    margin-bottom: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.MultipleFilesInput_label__n-KVH {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1.5rem;
}

.MultipleFilesInput_fileInput__1-8qm {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 3.5rem;
   text-align:center;
}

.MultipleFilesInput_fileInput__1-8qm input {
    width: 15rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
}

.MultipleFilesInput_fileCustom__1-PsF {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 1rem 2.5rem;
    text-align: center;
    border-radius: 5px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size: 1.6rem;
    color: white;
    background-color: #0000ff;
    transition: all .2s ease-in-out;
}

.MultipleFilesInput_fileCustom__1-PsF:hover {
    background-color: #14141b;

}

.MultipleFilesInput_filePreviewWrapper__2njGG {
    border-top: 1px solid #b4b4b4;
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.MultipleFilesInput_documentsPreviewWrapper__1rXMg {
    flex-direction: column;
}

.MultipleFilesInput_filePreview__1IEdV {
    width: 20rem;
    margin: 2rem 1.5rem;
    position: relative;
}

.MultipleFilesInput_documentsPreview__2YZ4i{
    width: auto;
    padding-bottom: 1rem;
    border-bottom: 1px solid #b4b4b4;
}

.MultipleFilesInput_document__1ecwt {
    font-size: 1.6rem;
    color: #0000ff;
    font-weight: 500;
}

.MultipleFilesInput_document__1ecwt:hover {
    color: #14141b;
}

.MultipleFilesInput_document__1ecwt i {
    font-size: 2rem;
    margin-right: 8px;
}

.MultipleFilesInput_filePreview__1IEdV img{
    width: 100%;
}

.MultipleFilesInput_filePreviewRemove__2KIaO {
    position: absolute;
    bottom: 100%;
    left: 100%;
    font-size: 16px;
    color: #0000ff;
    padding: 3px;
    cursor: pointer;
}


.YouTubeVideo_preview__1e8oy {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.YouTubeVideo_preview__1e8oy iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.SelectOptionsDropdown_selectOptions__eY-KO {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.SelectOptionsDropdown_label__2IBuf {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1.5rem;
}

.SelectOptionsDropdown_select__32SBB {
    width: 50%;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    font-size: 1.6rem;
    padding: 1rem 0.5rem;
}

.SelectOptionsDropdown_select__32SBB:focus {
    outline: none;
}

.SelectOptionsDropdown_option__84Z50 {
    font-size: 1.6rem;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size:62.5%;
}


body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  text-decoration: none;
}

@media all and (max-width: 1040px) {
  html {
    font-size:55.5%;
  }
}

@media all and (max-width: 768px) {
  html {
    font-size:45.5%;
  }
}

