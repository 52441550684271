.sidebarList {
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.sidebarLink{
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    padding: 1rem 2.5rem;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.sidebarList:hover {
    background-color: #40435a;
}

.linkIcon {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullTitle {
    display: inline-flex;
    justify-content: space-between;
    flex: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
    position: absolute;
    left: 55px;
}

.fullTitle.active {
    width: 20rem;
   opacity: 1;
   visibility: visible;
}

.subList {
    position: absolute;
    left: 100%;
    top: 0;
    width: 28rem;
    list-style-type: none;
    background-color: #14141b;
    display: none;
}

.subList.active {
    display: block;
    
}

@media all and (max-width: 468px) {

    .sidebarList {
        font-size: 1.8rem;
        flex-wrap: wrap;
        width: 100%;
    }

    .sidebarList.active{
        background-color: #40435a;
    }

    .fullTitle {
        flex: 1;
        opacity: 1;
        visibility: visible;
        position: relative;
    }

    .subList {
        position: relative;
        left:0;
        top: 0;
        width: 100%;
        display: none;
        transition: all 0.2 ease-in-out;
        border-top: 1px solid #14141b;
        border-bottom: 1px solid #14141b;
        margin: 2rem 0;
        background-color: #40435a;
    }

    .subList.active {
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        display: block;
    }
    
}
