.buttonsContainer {
    display: flex;
    justify-content: flex-end;
}

.confirmationOverlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 5;
    top: 0;
    left: 0;
}

.confirmation {
    position: absolute;
    top: 26%;
    left: 25%;
    background-color: white;
    border: 1px solid #b4b4b4;
    width: 60%;
    padding: 5rem 3rem;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}



.confirmButtons {
    display: flex;
    justify-content: flex-end;
}

.text {
    font-size: 1.8rem;
    margin-bottom: 5rem;
    margin-top: 1rem;
}

.lockScroll {
    overflow-y: hidden;
}