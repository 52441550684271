.buttonsContainer {
    display: flex;
    justify-content: flex-end;
}

.prevBtn {
    margin-right: auto;
}

.formError {
    color: #d40404;
    font-size: 1.6rem;
    text-align: right;
    margin-top: 5px;
    font-weight: 500;
}
