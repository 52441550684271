.selectOptions {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.label {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1.5rem;
}

.select {
    width: 50%;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    font-size: 1.6rem;
    padding: 1rem 0.5rem;
}

.select:focus {
    outline: none;
}

.option {
    font-size: 1.6rem;
}
