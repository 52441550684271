.header{
    background-color:#0000FF;
    padding: 1rem 0;
    height: 6rem;
}

.headerContainer {
    max-width: 1200px;
    width: 97%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.mobMenuIcon {
    display: none;
}

.logo img {
    width: 2.5rem;
}

.title {
    color: white;
    align-self: center;
    font-size: 2.6rem;
    letter-spacing: 1px;
}

.userInfo {
    align-self: flex-end;
    font-size: 1.6rem;
    color: white;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.userInfo:hover {
    color: #7EC8E3;
}

.icon{
    margin-right: 1.5rem;
    font-size: 2.2rem;
}

@media all and (max-width: 768px) {
    .userName{
        display: none;
    }

    .logoMenuContainer {
        display: flex;
        align-items: center;
    }

    .mobMenuIcon {
        display: block;
        color: white;
        font-size: 20px;
        margin-left: 8px;
        cursor: pointer;
    }
}