.sideList {
    border-right: 1px solid #b4b4b4;
    margin-right: 1.5rem;
    overflow-y: auto;
    height:  calc(100vh - 6rem);
    padding: 1.5rem 1rem;
    width: 20%;
    display: flex;
    flex-direction: column;
}

.noResults{
    font-size: 1.6rem;
}

.moreBtn {
    border: 1px solid #0000ff;
    padding: 5px 8px;
    border-radius: 30px;
    background:transparent;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #0000ff;
    cursor: pointer;
    margin-top: 2rem;
    align-self: center;
}

.moreBtn:hover {
    border: 1px solid #0000ff;
    background-color: #0000ff;
    color: white;
}

.moreBtn:focus{
    outline: none;
}

@media all and (max-width: 768px) {
    .sideList{
        width: 100%;
        border-right: none;
        height: 30rem;
        border-bottom: 2px solid #b4b4b4;
        background-color: #f7f7f7;
    }
}