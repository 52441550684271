.searchInput{
    display: flex;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    align-items: center;
    padding: 1rem .5rem;
    margin-bottom: 2rem;
}

.searchInput input{
    width:100%;
    border: none;
    font-size: 1.6rem;
    background: transparent;
    
}

.searchInput input:focus{
    outline: none;
}

.searchInput input::placeholder{
    color: #b4b4b4;
}

.icon{
    color: #b4b4b4;
    font-size: 1.6rem;
    margin-right: 5px;
}