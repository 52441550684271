.login {
    width: 100%;
    height: 100vh;
    background: linear-gradient(-45deg, #0000FF 0%, #0000FF 50%, transparent 50%);
    position: relative;
}

.loginBox {
    width: 70%;
    height: 60rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(-45deg, #0000FF 0%, #0000FF 50%, transparent 50%);
    box-shadow: 0px 0px 8px 0.5px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-between;
}

.titleContainer {
    align-self: center;
    width: 43%;
    text-align: center;
}

.logo{
    width: 12rem;
    margin: 0 auto;
}

.logo img{
    width: 100%;
}

.mainTitle h2{
    font-size: 3rem;
    color: #171718;
}

.logInTitle {
    font-size: 2.2rem;
    letter-spacing: 2px;
}


@media all and (max-width: 1040px) {
    .loginBox {
        width: 90%;
    }
}

@media all and (max-width: 550px) {

    .loginBox{
        width: 95%;
        height: 60rem;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 1.5rem;
    }

    .titleContainer {
        align-self: flex-start;
        width: 75%;
    }

    .mainTitle h2{
        margin-bottom: 2rem;
    }
    
}