.text-editor{
    position: relative;
    margin-bottom: 3rem;
}

.editor-label {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1rem;
}

.ck.ck-editor__editable_inline{
    padding: 1rem 2rem;
    height: 30rem;
    overflow-y: auto;
}

.ck-editor p{
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
}

.ck-editor ul li,
.ck-editor ol li{
    font-size: 1.6rem;
}

.ck-editor figure {
    width: 80%;
}

.ck.ck-editor h2{
    font-size: 2.2rem;
    text-align: center;
}

.ck.ck-editor h3{
    font-size: 2rem;
    text-align: center;
}

.ck.ck-editor h4{
    font-size: 1.8rem;
    text-align: center;
}

.ck-editor table td{
    font-size: 12px;
}

