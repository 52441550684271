.languageTab {
    background-color: #141414;
    color: white;
    border-radius: 0 0 5px 5px;
    padding: .5rem 2rem;
    font-size: 1.6rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.languageTab:hover{
    background-color: #707070;
}

.languageTab.active{
    background-color: #707070;
}