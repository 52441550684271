.inputWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
}

.label {
    font-size: 1.6rem;
    font-weight: 500;
    color: #171718;
    margin-bottom: 1rem;
}

.input {
    width: 100%;
    border: 1px solid #b4b4b4;
    padding: 1rem .5rem;
    border-radius: 5px;
    font-size: 1.6rem;
}

.input:focus{
    outline: none;
}

.errorMsg {
    color: #d40404;
    font-size: 12px;
    margin-top: 5px;
}

.invalid {
    border: 1px solid #d40404;
}