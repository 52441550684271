.loginInput {
    position: relative;
    width: 100%;
    display: flex;
    margin: 3.5rem 0 ;
}

.label {
    position: absolute;
    margin: 1rem;
    top: 0;
    left: .5rem;
    font-size: 2rem;
    padding: 0 .5rem;
    background:#0000FF;
    transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
    color: white;
}

.input {
    width: 100%;
    padding:1rem 2rem;
    border: 1px solid white;
    font-size: 2rem;
    background:transparent;
    border-radius: 3rem;
    color: white;
}

.input:focus {
    outline: none;
}

.input:focus + label,
.active + label {
    top: -2.5rem;
    font-size: 1.8rem;

}

.input.invalid {
    border: 1px solid #f59f4e;
}

.input.invalid + label {
    color: #f59f4e;
}

.errorMsg{
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 3px;
    font-size: 1.2rem;
    color:#f59f4e;
}