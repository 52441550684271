.subItem{
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    padding: 1rem 2.5rem;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    display: none;
}

.subItem.active {
    display: block;
}

.subItem:hover{
    background-color: #40435a;
}

.linkIcon{
    margin-right: 5px;
}

@media all and (max-width: 468px) {
    .subItem{
        padding: 2rem 3.5rem;
        
    }
}