.userProfile {
    width: 70%;
    margin: 0 auto;
}

.button {
    background: #0000ff;
    border: 1px solid #0000ff;
    color: white;
    padding: 1.5rem 4rem;
    border-radius: 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button:hover {
    background-color: #14141b;
    border: 1px solid #14141b;
    font-size: 1.6rem;
}